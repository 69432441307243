




























































































import { debounceProcess } from "@/helpers/debounce";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataCalendar } from "@/models/interface/settings.interface";
import { invoiceServices } from "@service/invoice.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
// import printJS from "print-js";
import { Component, Vue } from "vue-property-decorator";

interface LoadingComponent {
  loadingClose: boolean,
  loadingTaxMonth: boolean,
  loadingBranch: boolean,
}

@Component
export default class ReportTaxInvoice extends Vue {

  form = this.$form.createForm(this, { name: "reportTaxInvoice" })
  page = 1
  limit = 10
  params:RequestQueryParamsModel = {}
  totalData = 0
  loading:LoadingComponent = {
    loadingClose: false,
    loadingTaxMonth: false,
    loadingBranch: false
  }
  dataTaxMonth:DataCalendar[] = []
  dataBranch = [] as DataWarehouseBranch[]

  formRules = {
    taxMonth: {
      label: "lbl_tax_month_year",
      name: "taxMonth",
      placeholder: "lbl_tax_month_year_placeholder",
      decorator: [
        "taxMonth",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  }

  handleReset () {
    this.form.resetFields();
  }

  handleDownload () {
    this.form.validateFields((err,res) => {
      if(err) return;
      const params: RequestQueryParamsModel = {
        branchId: res.branch,
        taxMonth: res.taxMonth
      };
      this.loading.loadingClose = true;
      invoiceServices
        .printTaxInvoice(params)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response],{type: "text/csv;charset=utf-8;"}));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report_tax_invoice.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => this.loading.loadingClose = false);
    });
  }

  getBranch (valueSearch) {
    const params:RequestQueryParamsModel = {
      page: 0,
      limit: 10
    };
    if(valueSearch) params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then((response) => {
        this.dataBranch = response.data;
      })
      .finally(() => this.loading.loadingBranch = false);
  }

  getTaxMonth (valueSearch) {
    const params:RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      modul: "PO"
    };
    if(valueSearch) params.search = `month~*${valueSearch}*`;
    this.loading.loadingTaxMonth = true;
    settingsServices
      .listOfCalendar(params, "")
      .then((response) => {
        this.dataTaxMonth = response.data.filter((dataFilter) => !dataFilter.month?.includes("Adj"));
      })
      .finally(() => this.loading.loadingTaxMonth = false);
  }

  filterOption (input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }

  created () {
    this.getTaxMonth = debounceProcess(this.getTaxMonth, 400);
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getTaxMonth("");
    this.getBranch("");
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
